import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// React Bootstrap
import { Container } from "react-bootstrap"

import Consultation from "../components/free-consultation"

const SecondPage = () => (
  <Layout>
    <Seo title="Testimonials" />
    <Container className="singleCol">
      <h1>Testimonials from Clients</h1>
      <p><em>Contact information for reviewers is available on request following the free project consultation.</em></p>

      <blockquote>
        <p>We love the house and can’t thank you enough.  Not only do we have more breathing room, but from an aesthetic and practical perspective it’s fantastic.</p>
        <cite>David Y.</cite>
      </blockquote>
      
      <blockquote>
        <p>
        When we decided we needed to overhaul old and funky kitchen, Jim was the perfect person to draw up the new plans. He was sensitive to our need to keep the renovation affordable, offering different remodel options to accommodate our budget, and included a plan that could be implemented over time and in stages (without it looking like we were forever in the midst of construction). He was unfazed by the unusual size and shape of our kitchen, and offered creative and practical ways to maximize the space. Jim went above and beyond to help us make sense of our different options, and made himself available for our many questions. In addition to being a fantastic architect, Jim is a warm, reliable and approachable person. We couldn't recommend him more highly.
        </p>
        <cite>Carla A., Santa Barbara, California</cite>
      </blockquote>

      <blockquote>
        <p>Remodeling jobs always contain tons of hassle, but at least it didn't come from my architect this time. Jim brings the professionalism from having worked countless large-scale projects and combines it with what seems to be a love for the small-scale remodel work he is doing now. I can't recommend him enough.</p>
        <cite>Richard H., San Francisco, California</cite>
      </blockquote>

      <blockquote>
        <p>
        We called in Jim Kautz for architectural ideas when we found ourselves in a very tough situation.  We had taken on the responsibility of picking up in midstream the renovation of a lovely small cottage that was left not only in mid-renovation, but without plans that we could consult.  Many problems - location of a staircase, of bathrooms, of walls to be added and removed - were staring us in the face.  Jim came up with a plan that is very creative and that has worked out wonderfully.   The ideas we had been playing with for layout and organization of the space were completely eclipsed by the plan that Jim Kautz drafted for us.  His basic layout continues to delight us as we use it. Along the way, Jim was cooperative and responsive to every question, we asked him.
        </p>
        <p>
          Our pleasure with Jim Kautz' work does not stop with the great ideas he offered.  When we decided to do something a bit differently from how he would have liked it, he let us know his ideas and then proceeded to help us implement our alternative.  There was nothing egotistical or dramatic in his dealings with us.  He was cooperative and helpful every step of the way.  His experience and professionalism are evident in all his work.
        </p>
        <p>
          Finally, we are delighted with the cost of Jim Kautz' services.  Of course he charges professional rates for the services he renders, but he never padded the time, or suggested work that was not necessary.   Jim respected the limits of our budget.  We received architectural services that we are really delighted with at a relatively modest cost.
        </p>
        <cite>Bernard W., Woodacre, California</cite>
      </blockquote>

      <blockquote>
        <p>Jim at Marin County Remodel was fantastic. We had been working on a large remodel and addition in San Francisco (no easy task), and were very stuck on a few engineering, design, and architectural issues. A friend recommended we consult with Jim and we couldn't be happier we did. Jim brought a lot of creative and practical advice from his years of experience in the industry and solved multiple problems we had encountered, including how to design a wide open staircase for our two story addition while not taking away from our existing bedroom. It's just one of our favorite parts of our new house and we would highly recommend Jim for any project.</p>
        <cite>John A., San Francisco, California</cite>
      </blockquote>

    <hr />
    </Container>

    <div className="mt-3">
      <Consultation  />
    </div>
  </Layout>
)

export default SecondPage
